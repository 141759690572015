import styled from 'styled-components';
import IconCheck from '../../images/icon-check.png';

export const HeaderH2 = styled.h2`
  text-align: center;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'Figtree', sans-serif;
  font-size: 28px;
  line-height: 35px;
  color: #55aa4c;
  @media (max-width: 1399px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const HeadingH4 = styled.h4`
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const ParaText = styled.p`
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 40px;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;

export const BlockQuotes = styled.blockquote`
  width: 100%;
  padding: 60px;
  text-align: center;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  border-radius: 20px;
  margin: 0 !important;
  @media (max-width: 991px) {
    width: 100%;
    padding: 30px;
  }
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
`;
export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;
export const OrderedList = styled.ol`
  margin: 0 0 30px 0;
  padding: 0;
  list-style-type: none;
  counter-reset: standard-counter;
`;
export const ListItem = styled.li`
  padding: 0 0 15px 50px;
  background: url(${IconCheck}) no-repeat 0 1px;
  background-size: 30px auto;
  list-style: none;
  margin: 0;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  @media (max-width: 767px) {
    padding-top: 1px;
    padding-left: 40px;
    background-position: 0 0;
    background-size: 25px auto;
  }
`;
export const ButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 17px 35px 13px 35px;
  margin: 0 5px 5px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #55aa4c;
  border: 2px solid #55aa4c;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 10px 20px 6px 20px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
  }
  :hover {
    top: -5px;
    color: #55aa4c;
    border-color: #55aa4c;
    box-shadow: 0 5px 0 0 #55aa4c;
  }
`;

export const ImageStyle = styled.img`
  width: auto;
  height: auto;
  margin: 0 0 30px 0;
`;
